import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component() {
      return import(/* webpackChunkName: "home" */ '@/views/Home.vue');
    },
  },
  {
    path: '/formations',
    name: 'formations',
    component() {
      return import(/* webpackChunkName: "formations" */ '../views/ProductList.vue');
    },
  },
  {
    path: '/formations/latin-serein-khagne-reussie',
    name: 'formation-latin-serein',
    component() {
      return import(/* webpackChunkName: "formation-latin-serein" */ '../views/LatinSerein.vue');
    },
  },
  {
    path: '/formations/oral-serein-bac-reussi',
    name: 'oral-serein-bac-reussi',
    component() {
      return import(/* webpackChunkName: "oral-serein-bac-reussi" */ '../views/OralFrancais.vue');
    },
  },
  {
    path: '/formations/oral-serein',
    name: 'oral-serein',
    component() {
      return import(/* webpackChunkName: "oral-serein-bac-reussi" */ '../views/OralFrancaisSeul.vue');
    },
  },
  {
    path: '/formations/paiement-success',
    name: 'paiement-success',
    component() {
      return import(/* webpackChunkName: "formation-latin-serein" */ '../views/PaiementSuccess.vue');
    },
  },
  {
    path: '/products',
    name: 'products',
    component() {
      return import(/* webpackChunkName: "products" */ '../views/ProductList.vue');
    },
  },
  {
    path: '/products/:product',
    name: 'product',
    component() {
      return import(/* webpackChunkName: "product" */ '../views/Product.vue');
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
