import Vue from 'vue';
import {
  AlertPlugin,
  BIcon,
  BIconAt,
  BIconBook,
  ButtonPlugin,
  CardPlugin,
  DropdownPlugin,
  FormPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  LayoutPlugin,
  NavbarPlugin,
  PaginationPlugin,
  SpinnerPlugin,
} from 'bootstrap-vue';
import App from './App.vue';
import router from './router';

import './magister.scss';

Vue.use(AlertPlugin);
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(DropdownPlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormSelectPlugin);
Vue.use(LayoutPlugin);
Vue.use(NavbarPlugin);
Vue.use(PaginationPlugin);
Vue.use(SpinnerPlugin);

Vue.component('BIcon', BIcon);
Vue.component('BIconAt', BIconAt);
Vue.component('BIconBook', BIconBook);

Vue.config.productionTip = false;

Vue.filter('author', (product, lang) => {
  if (lang && product[`author_${lang}`]) {
    return product[`author_${lang}`];
  }
  return product.author;
});
Vue.filter('title', (product, lang) => {
  if (lang && product[`title_${lang}`]) {
    return product[`title_${lang}`];
  }
  return product.title;
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
