<template>
  <div class="h-100 d-flex flex-column justify-content-between">
    <b-navbar
      ref="navbar"
      type="none"
      toggleable="md"
    >
      <b-navbar-brand to="/">
        <logo style="height: 1.6em" />
      </b-navbar-brand>

      <b-navbar-toggle class="ml-auto" target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown text="Collections livres">
            <b-dropdown-item to="/products/?collection=latin">Latin<div class="float-right img-thumbnail" style="background: #84aff5;width: 1.2rem; height: 1.2rem;"></div></b-dropdown-item>
            <b-dropdown-item to="/products/?collection=grec">Grec<div class="float-right img-thumbnail" style="background: #f5ca84;width: 1.2rem; height: 1.2rem;"></div></b-dropdown-item>
            <b-dropdown-item to="/products/?collection=middleages">Médiéval<div class="float-right img-thumbnail" style="background: #aff584;width: 1.2rem; height: 1.2rem;"></div></b-dropdown-item>
          </b-nav-item-dropdown>
          <!--b-nav-item-dropdown text="Formations">
            <b-dropdown-item to="/formations/oral-serein-bac-reussi">Oral serein, Bac réussi</b-dropdown-item>
            <b-dropdown-item to="/formations/latin-serein-khagne-reussie">Latin serein, khâgne réussie</b-dropdown-item>
          </b-nav-item-dropdown-->
        </b-navbar-nav>
      </b-collapse>

      <b-navbar-nav class="ml-auto">
        <a
          class="btn btn-primary"
          href="https://www.armavirumque.fr/texts"
          tag="button"
          target="_blank"
        >
          <b-icon
            icon="book"
            aria-hidden="true"
            class="mr-1"
          />
          Revenir à la bibliothèque
        </a>
      </b-navbar-nav>
    </b-navbar>

    <router-view
      class="flex-grow-1"
      lang="fr"
    />

    <footer
      class="pt-2 pb-2 bg-light border-top border-primary text-dark"
      :style="'border-top-width: 2px !important; ' + ($route.name === 'text'?'display:none;':'')"
    >
      <b-container class="my-2">
        <b-row>
          <b-col lg="6">
            &copy;
            <logo
              color="#000"
              height="15"
            />
          </b-col>
          <b-col
            class="d-none d-sm-block"
            lg="3"
            md="6"
          />
          <b-col
            lg="3"
            md="6"
          >
            <a
              href="https://www.armavirumque.fr/mentions-legales"
              target="_blank"
            >
              Mentions légales
            </a><br>
            <!--a
              href="/cgv.pdf"
              target="_blank"
            >
              Conditions générales de vente
            </a-->
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue';

export default {
  components: {
    Logo,
  },
};
</script>

<style>
#app {
    position: absolute;
    width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
