<template>
  <svg
    height="9.1808929mm"
    viewBox="0 0 79.111519 9.180893"
    version="1.1"
  >
    <g transform="translate(-15.380712,-28.412282)">
      <g
        aria-label="rma"
        style="fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.385162px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
        transform="translate(0.26458333)"
      >
        <path
          d="m 28.879239,36.432884 q -0.0388,0.04657 -0.543277,0.06985 -0.03881,0 -0.1397,0.0078 -0.09313,0.0078 -0.116417,0.0078 -0.411339,0 -0.667455,-0.256117 -0.124178,-0.124178 -0.527756,-0.752828 -0.162983,-0.287161 -0.333728,-0.574322 -0.217311,-0.341488 -0.442383,-0.551038 -0.170744,-0.155223 -0.682978,-0.155223 -0.07761,0 -0.07761,0.09313 v 1.396999 q 0,0.760589 0.411339,0.760589 0.06985,0 0.108656,0.03104 -0.248356,0.03881 -0.263878,0.03881 -0.02328,0 -0.318206,-0.0078 -0.287161,-0.0078 -0.302683,-0.0078 -0.147461,0 -0.667455,0.06985 0.06209,-0.03881 0.186266,-0.178506 0.06985,-0.116416 0.06985,-0.853722 l 0.05433,-1.071033 V 33.98813 q 0,-0.01552 0.01552,-0.194028 0.01552,-0.178505 0.01552,-0.194028 V 33.08008 l -0.03881,-1.016705 q 0,-0.357011 -0.162983,-0.760589 -0.0078,-0.03881 -0.07761,-0.06209 -0.09313,-0.03104 -0.09313,-0.03104 0.108656,-0.03881 0.170745,-0.03881 l 0.434622,-0.01552 q 0.232833,0 0.613127,-0.01552 l 0.807156,-0.03881 q 0.613128,0 1.063272,0.248356 0.605367,0.333727 0.605367,1.016705 0,0.465667 -0.248356,0.869244 -0.162983,0.263878 -0.473428,0.543278 -0.271639,0.248356 -0.287161,0.287161 0,0.01552 0.194028,0.310444 0.271639,0.4191 0.395817,0.620889 0.20955,0.34925 0.4191,0.6985 0.256116,0.403578 0.481188,0.574322 0.07761,0.06209 0.186267,0.09313 0.194028,0.05433 0.232833,0.06985 z m -1.738488,-3.42265 q 0,-0.543278 -0.403578,-1.1176 -0.457906,-0.651933 -1.055511,-0.651933 -0.2794,0 -0.263878,0 l -0.0388,2.910416 h 0.519994 q 0.760589,0 0.985661,-0.201789 0.256117,-0.20955 0.256117,-0.9094 z"
          :style="`fill:${color};fill-opacity:1;stroke-width:0.385162px`"
        />
        <path
          d="m 36.809517,36.463928 q -0.04657,0.03104 -0.20955,0.03104 l -0.931333,0.03881 q -0.325967,0 -0.325967,-0.131939 0,-0.03104 0.01552,-0.09313 l -0.03104,-2.700866 -0.100894,-1.528939 q -0.5588,1.202972 -1.109839,2.405944 -0.884766,1.862666 -1.094316,1.862666 -0.07761,0 -0.993422,-1.722966 -1.109839,-2.087739 -1.334911,-2.452511 -0.116417,0.915811 -0.116417,1.265061 L 30.46869,35.08245 q 0.0078,0.01552 0,0.357012 -0.01552,0.318205 0.232834,0.644172 0.248355,0.318205 0.574322,0.333727 -0.01552,0.06209 -0.108656,0.06209 -0.01552,0 -0.271638,-0.0078 -0.248356,-0.01552 -0.271639,-0.01552 -0.457906,0 -0.776111,0.100894 0.0388,-0.147461 0.1397,-0.411338 0.09313,-0.240595 0.1397,-0.465667 0.05433,-0.294922 0.194027,-1.459089 0.170745,-1.443566 0.170745,-2.017888 0,-0.853722 -0.605367,-1.063272 0.03104,-0.03105 0.201789,-0.03105 0.124178,-0.0078 0.318206,0.0078 0.201788,0.01552 0.194027,0.01552 l 0.574322,-0.05433 q 0.07761,0 0.124178,0.124178 0.310445,0.869244 1.055511,2.281766 0.667456,1.2573 1.125361,1.948038 0.108656,-0.131938 0.527756,-1.102077 0.3727,-0.76835 0.667455,-1.5367 0.364772,-0.830439 0.535517,-1.544461 0.589844,-0.05433 1.055511,-0.08537 0,0.03104 -0.09313,0.09313 -0.05433,0.03105 -0.06985,0.240595 -0.03104,0.318205 -0.03104,0.341489 l 0.0078,1.808338 q 0.0388,0.90805 0.06209,1.272822 0.06209,0.776111 0.170744,1.265061 0.03881,0.162983 0.248356,0.201789 0.217311,0.03104 0.248355,0.07761 z"
          :style="`fill:${color};fill-opacity:1;stroke-width:0.385162px`"
        />
        <path
          d="m 43.508082,36.456167 -0.877005,0.04657 q -0.457906,0 -0.791633,-0.667456 -0.194028,-0.450144 -0.388056,-0.892528 -0.147461,-0.380294 -0.512233,-1.071033 h -1.567744 q -0.116417,0 -0.302684,0.403578 -0.09313,0.240594 -0.186266,0.473428 -0.310445,0.729544 -0.310445,1.055511 0,0.325966 0.248356,0.426861 0.302683,0.100894 0.605366,0.194027 l -1.032227,0.116417 q -0.116417,0 -0.403578,-0.03104 -0.287161,-0.02328 -0.411339,-0.02328 0.411339,-0.310444 0.714022,-0.923572 0.310445,-0.620889 0.985661,-2.196394 0.745067,-1.74625 0.745067,-1.940277 0,-0.124178 -0.09313,-0.201789 -0.09313,-0.08537 -0.217311,-0.08537 -0.01552,0 -0.06985,0.0078 -0.06209,0 -0.07761,0 -0.03881,0 -0.06985,-0.03104 0,-0.0388 0.037,-0.05433 0.09313,-0.01552 0.170744,-0.02328 l 0.884766,-0.02328 q 0.178506,0 0.372534,0.395817 0.100894,0.248355 0.20955,0.504472 0.372533,0.993422 0.752827,1.986844 0.582084,1.459088 1.102078,2.227438 0.09313,0.147461 0.271639,0.232834 0.155222,0.07761 0.162983,0.09313 z m -2.607733,-2.739672 v -0.06209 q 0,-0.01552 -0.527755,-1.397 -0.06209,-0.178505 -0.225072,-0.442383 l -0.799395,1.901472 z"
          :style="`fill:${color};fill-opacity:1;stroke-width:0.385162px`"
        />
      </g>
      <g
        aria-label="A   V"
        :style="`fill:${color};fill-opacity:1;stroke:none;stroke-width:0.465936px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1`">
        <path
          d="m 24.205432,36.53709 -1.275646,0.06773 q -0.666045,0 -1.151467,-0.970846 -0.282223,-0.654756 -0.564445,-1.298223 -0.21449,-0.553156 -0.745068,-1.557868 h -2.280358 q -0.169333,0 -0.440267,0.587022 -0.135467,0.349956 -0.270933,0.688623 -0.451556,1.061157 -0.451556,1.535291 0,0.474134 0.361244,0.620889 0.440267,0.146756 0.880535,0.282223 l -1.501424,0.169333 q -0.169334,0 -0.587023,-0.04516 -0.417689,-0.03387 -0.598312,-0.03387 0.598312,-0.451556 1.038579,-1.343379 0.451556,-0.903112 1.433691,-3.194759 1.083734,-2.540002 1.083734,-2.822225 0,-0.180622 -0.135467,-0.293511 -0.135467,-0.124178 -0.316089,-0.124178 -0.02258,0 -0.1016,0.01129 -0.09031,0 -0.112889,0 -0.05645,0 -0.1016,-0.04516 0,-0.05644 0.124178,-0.07902 0.135466,-0.02258 0.248355,-0.03387 l 1.286935,-0.03387 q 0.259645,0 0.541867,0.575734 0.146756,0.361245 0.304801,0.733779 0.541867,1.444979 1.095023,2.889958 0.846667,2.122314 1.603024,3.239915 0.135467,0.214489 0.395111,0.338667 0.225778,0.112889 0.237067,0.135466 z m -3.79307,-3.984981 v -0.09031 q 0,-0.02258 -0.767646,-2.032002 -0.09031,-0.259644 -0.327378,-0.643467 l -1.162756,2.765781 z"
          :style="`fill:${color};fill-opacity:1;stroke-width:0.465936px`"
        />
        <path
          d="m 53.496425,28.612282 q -2.05458,4.052716 -3.804359,8.161875 -0.07902,-0.03387 -0.440267,-0.7112 -0.09031,-0.169334 -0.778935,-1.828802 -0.756356,-1.806224 -1.174045,-2.664181 -0.790223,-1.63689 -1.490135,-2.494847 -0.07902,-0.09031 -0.2032,-0.112889 -0.112889,-0.03387 -0.237067,-0.06773 0.05644,-0.1016 0.237067,-0.1016 0.225778,0 0.688623,-0.01129 0.462845,-0.02258 0.440267,-0.02258 0.530578,0 0.688623,0.06773 0.01129,0.01129 0.338667,0.801511 0.248355,0.643468 0.778934,1.919114 0.6096,1.411112 1.591735,3.510848 0.417689,-0.846668 1.049867,-2.415825 0.982135,-2.427114 0.982135,-3.18347 0,-0.237067 -0.1016,-0.474134 -0.135467,-0.3048 -0.349956,-0.270933 v -0.101601 z"
          :style="`fill:${color};fill-opacity:1;stroke-width:0.465936px`"
        />
      </g>
      <g
        aria-label="irumque"
        :style="`letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1`">
        <path
          d="m 55.050001,36.702436 q 0,0.01552 -0.03104,0.06209 h -1.210733 l 0.1397,-1.311628 0.03104,-1.26506 q 0,-1.74625 -0.0078,-1.738489 0,-0.06985 -0.01552,-0.194028 -0.0078,-0.248355 -0.01552,-0.504472 -0.0078,-0.263878 -0.217311,-0.457905 0.07761,0 0.256116,-0.0078 0.201789,0 0.186267,0 0.287161,0 0.48895,-0.03104 0.20955,-0.03881 0.178506,-0.03881 h 0.06209 q -0.124177,1.039989 -0.155222,2.429228 l -0.01552,2.483555 q 0.01552,0.124178 0.03104,0.248356 0.01552,0.155222 0.08537,0.240594 0,0.0078 0.20955,0.08537 z"
          :style="`fill:${color};fill-opacity:1;stroke-width:0.264583px`"
        />
        <path
          d="m 61.000468,36.679153 q -0.03881,0.04657 -0.543278,0.06985 -0.03881,0 -0.1397,0.0078 -0.09313,0.0078 -0.116417,0.0078 -0.411338,0 -0.667455,-0.256117 -0.124178,-0.124177 -0.527756,-0.752827 -0.162983,-0.287161 -0.333727,-0.574322 -0.217311,-0.341489 -0.442383,-0.551039 -0.170745,-0.155222 -0.682978,-0.155222 -0.07761,0 -0.07761,0.09313 v 1.397 q 0,0.760588 0.411339,0.760588 0.06985,0 0.108655,0.03104 -0.248355,0.03881 -0.263878,0.03881 -0.02328,0 -0.318205,-0.0078 -0.287161,-0.0078 -0.302683,-0.0078 -0.147461,0 -0.667456,0.06985 0.06209,-0.0388 0.186267,-0.178505 0.06985,-0.116417 0.06985,-0.853722 l 0.05433,-1.071034 v -0.512233 q 0,-0.01552 0.01552,-0.194028 0.01552,-0.178505 0.01552,-0.194027 v -0.519995 l -0.03881,-1.016705 q 0,-0.357011 -0.162983,-0.760589 -0.0078,-0.0388 -0.07761,-0.06209 -0.09313,-0.03104 -0.09313,-0.03104 0.108655,-0.03881 0.170744,-0.03881 l 0.434622,-0.01552 q 0.232834,0 0.613128,-0.01552 l 0.807155,-0.03881 q 0.613128,0 1.063272,0.248356 0.605367,0.333728 0.605367,1.016705 0,0.465667 -0.248356,0.869245 -0.162983,0.263877 -0.473427,0.543277 -0.271639,0.248356 -0.287161,0.287161 0,0.01552 0.194027,0.310445 0.271639,0.4191 0.395817,0.620888 0.20955,0.34925 0.4191,0.6985 0.256117,0.403578 0.481189,0.574322 0.07761,0.06209 0.186266,0.09313 0.194028,0.05433 0.232834,0.06985 z m -1.738489,-3.42265 q 0,-0.543277 -0.403578,-1.117599 -0.457905,-0.651934 -1.055511,-0.651934 -0.279399,0 -0.263877,0 l -0.03881,2.910417 h 0.519995 q 0.760588,0 0.98566,-0.201789 0.256117,-0.20955 0.256117,-0.939095 z"
          :style="`fill:${color};fill-opacity:1;stroke-width:0.264583px`"
        />
        <path
          d="m 67.347479,36.601542 q -0.209549,0.0388 -0.411338,0.0388 0.0078,0 -0.108656,0.0078 -0.100894,0.0078 -0.162983,0 l -0.605367,0.09313 q -0.08537,-0.03104 -0.08537,-0.240595 0,-0.155222 0,-0.302683 -0.457905,0.240594 -0.752828,0.357011 -0.131938,0.04657 -0.5588,0.147461 -0.481188,0.108656 -0.651933,0.108656 h -0.248355 q -0.892528,0 -1.218494,-0.388056 -0.318206,-0.388055 -0.318206,-1.350433 0,-0.217311 0.0078,-0.496711 l 0.03104,-2.258483 q -0.03104,-0.287161 -0.06985,-0.4191 -0.06985,-0.256116 -0.225073,-0.325966 v -0.06209 q 0.551039,-0.03881 1.094317,-0.147461 v 0.08537 q 0,0.01552 -0.02328,0.170744 -0.02328,0.155222 -0.03104,0.225072 -0.03881,0.644172 -0.03881,0.566561 v 0.434623 q 0,0.01552 -0.01552,0.147461 -0.01552,0.124177 -0.01552,0.147461 v 1.435805 q 0,0.240594 0.124178,0.682978 0.124178,0.465666 0.20955,0.512233 0.263878,0.411339 0.644172,0.543278 0.256117,0.09313 0.714022,0.09313 0.566561,0 0.877005,-0.178506 0.434623,-0.248355 0.527756,-0.900288 l 0.04657,-1.800578 -0.01552,-1.265061 q -0.0078,-0.03104 -0.03104,-0.450144 -0.01552,-0.364772 -0.34925,-0.403578 0.02328,-0.06209 0.519994,-0.09313 0.597606,-0.03104 0.613128,-0.03881 0.04657,0.04657 0.04657,0.06209 l -0.155223,1.474611 q 0,0.4191 -0.03104,1.249539 -0.02328,0.830439 -0.02328,1.241777 0,0.248356 0.06985,0.597606 0.05433,0.318205 0.100894,0.395816 0.06985,0.131939 0.240595,0.194028 0.248355,0.08537 0.279399,0.108656 z"
          :style="`fill:${color};fill-opacity:1;stroke-width:0.264583px`"
        />
        <path
          d="m 75.479182,36.710197 q -0.04657,0.03104 -0.20955,0.03104 l -0.931333,0.03881 q -0.325967,0 -0.325967,-0.131939 0,-0.03104 0.01552,-0.09313 L 73.99681,33.854109 73.895916,32.32517 q -0.5588,1.202972 -1.109839,2.405944 -0.884767,1.862667 -1.094317,1.862667 -0.07761,0 -0.993422,-1.722967 -1.109838,-2.087738 -1.33491,-2.452511 -0.116417,0.915811 -0.116417,1.265061 l -0.108656,1.645356 q 0.0078,0.01552 0,0.357011 -0.01552,0.318205 0.232834,0.644172 0.248355,0.318205 0.574322,0.333728 -0.01552,0.06209 -0.108656,0.06209 -0.01552,0 -0.271639,-0.0078 -0.248355,-0.01552 -0.271638,-0.01552 -0.457906,0 -0.776111,0.100895 0.03881,-0.147462 0.1397,-0.411339 0.09313,-0.240595 0.139699,-0.465667 0.05433,-0.294922 0.194028,-1.459089 0.170745,-1.443566 0.170745,-2.017888 0,-0.853722 -0.605367,-1.063272 0.03104,-0.03104 0.201789,-0.03104 0.124178,-0.0078 0.318205,0.0078 0.201789,0.01552 0.194028,0.01552 l 0.574322,-0.05433 q 0.07761,0 0.124178,0.124178 0.310444,0.869244 1.055511,2.281766 0.667455,1.2573 1.125361,1.948039 0.108655,-0.131939 0.527755,-1.102078 0.333728,-0.76835 0.667456,-1.5367 0.364772,-0.830438 0.535516,-1.544461 0.589845,-0.05433 1.055511,-0.08537 0,0.03105 -0.09313,0.09313 -0.05433,0.03104 -0.06985,0.240594 -0.03104,0.318205 -0.03104,0.341489 l 0.0078,1.808338 q 0.0388,0.90805 0.06209,1.272822 0.06209,0.776111 0.170744,1.265061 0.03881,0.162984 0.248356,0.201789 0.217311,0.03104 0.248355,0.07761 z"
          :style="`fill:${color};fill-opacity:1;stroke-width:0.264583px`"
        />
        <path
          d="m 84.023195,37.230192 q -0.364772,0.162983 -1.102077,0.162983 -0.62865,0 -1.241778,-0.217311 -0.364772,-0.131939 -0.869244,-0.403578 -0.403578,-0.217311 -0.512234,-0.217311 -0.535516,0.178506 -0.760588,0.178506 -0.01552,0 -0.178506,0.0078 -0.155222,0.01552 -0.170744,0.01552 h -0.124178 q -1.024466,0 -1.722966,-0.737306 -0.6985,-0.737305 -0.6985,-1.738488 0,-1.272822 0.892527,-2.126544 0.892528,-0.861483 2.149828,-0.861483 1.109839,0 1.916994,0.714022 0.853722,0.752827 0.853722,1.885949 0,1.451328 -1.265061,2.242961 0.543278,0.434622 1.210733,0.745067 0.760589,0.341488 1.334911,0.341488 0.0078,0 0.116417,0.0078 0.108655,0 0.170744,0 z m -2.475794,-2.762956 q 0,-1.738488 -1.303866,-2.59221 -0.527756,-0.341489 -0.970139,-0.341489 -0.923572,0 -1.420283,0.613128 -0.450144,0.551038 -0.450144,1.451327 0,1.109839 0.822677,1.971322 0.822678,0.861483 1.963561,0.861483 0.706261,0 1.078794,-0.721783 0.2794,-0.543278 0.2794,-1.241778 z"
          :style="`fill:${color};fill-opacity:1;stroke-width:0.264583px`"
        />
        <path
          d="m 89.008858,36.601542 q -0.20955,0.0388 -0.411338,0.0388 0.0078,0 -0.108656,0.0078 -0.100894,0.0078 -0.162983,0 l -0.605367,0.09313 q -0.08537,-0.03104 -0.08537,-0.240595 0,-0.155222 0,-0.302683 -0.457906,0.240594 -0.752828,0.357011 -0.131939,0.04657 -0.5588,0.147461 -0.481188,0.108656 -0.651933,0.108656 h -0.248355 q -0.892528,0 -1.218495,-0.388056 -0.318205,-0.388055 -0.318205,-1.350433 0,-0.217311 0.0078,-0.496711 l 0.03104,-2.258483 q -0.03104,-0.287161 -0.06985,-0.4191 -0.06985,-0.256116 -0.225072,-0.325966 v -0.06209 q 0.551039,-0.03881 1.094317,-0.147461 v 0.08537 q 0,0.01552 -0.02328,0.170744 -0.02328,0.155222 -0.03104,0.225072 -0.03881,0.644172 -0.03881,0.566561 v 0.434623 q 0,0.01552 -0.01552,0.147461 -0.01552,0.124177 -0.01552,0.147461 v 1.435805 q 0,0.240594 0.124178,0.682978 0.124177,0.465666 0.20955,0.512233 0.263877,0.411339 0.644172,0.543278 0.256116,0.09313 0.714022,0.09313 0.566561,0 0.877005,-0.178506 0.434622,-0.248355 0.527756,-0.900288 l 0.04657,-1.800578 -0.01552,-1.265061 q -0.0078,-0.03104 -0.03104,-0.450144 -0.01552,-0.364772 -0.34925,-0.403578 0.02328,-0.06209 0.519994,-0.09313 0.597606,-0.03104 0.613128,-0.03881 0.04657,0.04657 0.04657,0.06209 l -0.155223,1.474611 q 0,0.4191 -0.03104,1.249539 -0.02328,0.830439 -0.02328,1.241777 0,0.248356 0.06985,0.597606 0.05433,0.318205 0.100894,0.395816 0.06985,0.131939 0.240594,0.194028 0.248356,0.08537 0.2794,0.108656 z"
          :style="`fill:${color};fill-opacity:1;stroke-width:0.264583px`"
        />
        <path
          d="m 94.292233,36.353186 q -0.178506,0.131939 -0.318206,0.4191 -0.116416,0.225072 -0.225072,0.450144 -0.0078,-0.108655 -0.05433,-0.294922 -0.100894,-0.201789 -0.923572,-0.201789 l -2.180872,0.05433 q 0.05433,-0.08537 0.100895,-0.4191 0.05433,-0.333727 0.05433,-0.5588 l 0.05433,-0.915811 v -0.962377 q 0,-0.0078 -0.0078,-0.147461 -0.0078,-0.1397 -0.0078,-0.155222 v -0.48895 q 0,-0.20955 -0.03104,-0.737306 -0.03881,-0.714022 -0.09313,-0.760589 -0.108655,-0.08537 -0.240594,-0.09313 -0.225072,-0.01552 -0.240595,-0.01552 0,-0.0078 0.0078,-0.02328 0.0078,-0.02328 0.0078,-0.03105 l 3.608916,-0.124178 q -0.108656,0.178506 -0.240595,0.48895 -0.02328,0.05433 -0.06985,0.186267 -0.0078,0 -0.03104,-0.0078 -0.01552,-0.0078 -0.02328,-0.0078 0.02328,-0.04657 0.02328,-0.09313 0,-0.131939 -0.217311,-0.217311 -0.170745,-0.07761 -0.310445,-0.07761 -0.04657,0 -0.06209,0.03105 -0.201789,-0.108656 -0.869245,-0.108656 -0.248355,0 -0.318205,0.03104 -0.06209,0.02328 -0.06985,0.225072 -0.0078,0.155222 -0.0078,0.310444 -0.01552,0.411339 -0.06209,1.226255 0,0.411339 -0.0078,0.465667 0.240594,0.03881 0.682977,0.03881 0.729545,0 1.381478,-0.225073 0.03104,0.03104 0.03104,0.05433 0,0.01552 -0.108655,0.287161 -0.124178,0.302684 -0.1397,0.364772 -0.03881,0 -0.07761,-0.07761 -0.03105,-0.08537 -0.06985,-0.116416 -0.256117,-0.162984 -1.195211,-0.162984 -0.07761,0 -0.473428,0.04657 -0.0388,0.06209 -0.0388,0.147461 v 1.722966 q 0,0.0078 0.0078,0.131939 0.0078,0.116417 0.0078,0.170745 0,0.147461 0.02328,0.256116 0.388056,0.108656 1.04775,0.108656 H 92.9418 q 0.240594,0 0.690738,-0.09313 0.457906,-0.100895 0.582084,-0.186267 l 0.06985,0.01552 z"
          :style="`fill:${color};fill-opacity:1;stroke-width:0.264583px`"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#002fa7',
    },
  },
};
</script>
